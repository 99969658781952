import { AuthorizeStep } from './../../authorizeStep';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { HttpClient, RequestInit } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer';

import { AuthorizeError, ForbiddenError, ServerError } from 'lib/error';
import { FileAttachment } from 'models';
import { AccountService } from 'services/account-service';

export type ResponseMeta<T> = {
  data: T;
  headers: Headers;
  status: number;
  statusText: string;
  url: string;
};

type GetOptions = {
  cache?: boolean;
};

type UnwrapArray<T> = T extends (infer U)[] ? U : T;

type Execute<T> = {
  url: string;
  config?: RequestInit;
  model?: ClassConstructor<UnwrapArray<T>>;
};

type Post<T> = {
  data?: object | string | number;
} & Execute<T>;

type Get<T> = Execute<T>;
type Delete = {
  url: string;
  config?: RequestInit;
};

type CacheEntry = {
  data: object | string | number;
  timestamp: number;
};

@autoinject
export class NetlogHttpClient {
  private subscriptions: Subscription[] = [];

  private cache = new Map<string, CacheEntry>();
  private cacheTimeout = 1000 * 60 * 2;

  constructor(
    router: Router,
    private httpClient: HttpClient,
    private accountService: AccountService,
    private eventAggregator: EventAggregator
  ) {
    this.setupAuthHeaders();

    const subscriptionLogin = this.eventAggregator.subscribe('auth_token_updated', this.setupAuthHeaders.bind(this));
    const subscriptionLogout = this.eventAggregator.subscribe('auth_token_cleared', this.clearAuthHeaders.bind(this));
    this.subscriptions.push(subscriptionLogin, subscriptionLogout);

    this.httpClient.configure((config) => {
      config.withInterceptor({
        response(response) {
          if (response.status === 401) {
            this.accountService.clearAuthToken();
            this.eventAggregator.publish('isLoggedIn', false);
            router.navigateToRoute('login');
            AuthorizeStep.auth.isAuthenticated = false;
          }
          return response;
        },
      });
    });
  }

  /**
   * Creates an error class instance based on the http status, with provided message.
   *
   * @param {number} httpStatus - the http status to generate error for
   * @param {string} [message] - message to addd to error object
   */
  protected createError(httpStatus: number, message?: string) {
    const messageFormat = message ? ': ' + message : '';

    if (httpStatus === 400) {
      return new AuthorizeError(`Invalid data${messageFormat}`);
    }
    if (httpStatus === 401) {
      return new AuthorizeError(`Requires authentication${messageFormat}`);
    }

    if (httpStatus === 403) {
      return new ForbiddenError(`Forbidden to perform action${messageFormat}`);
    }

    if (httpStatus === 404) {
      return new AuthorizeError(`Not found${messageFormat}`);
    }

    return new ServerError(`An unexpected server error occurred${messageFormat}`);
  }

  public setupAuthHeaders() {
    if (this.accountService.getAuthToken()) {
      this.httpClient.configure((config) => {
        config.withDefaults({
          headers: {
            Authorization: 'Bearer ' + this.accountService.getAuthToken(),
            pragma: 'no-cache',
            'cache-control': 'no-cache',
          },
        });
      });
    }
  }

  public clearAuthHeaders() {
    this.httpClient.configure((config) => {
      config.withDefaults({
        headers: {},
      });
    });
  }

  public async execWithResponseMeta<T>({ config, url, model }: Execute<T>) {
    let headers: object = {
      'Content-Type': config?.headers?.['Content-Type'] || 'application/json',
      Accept: 'application/json',
    };

    if (config?.headers) {
      headers = { ...headers, ...config.headers };
    }

    const response = await this.httpClient.fetch(url, {
      ...config,
      headers,
    });
    if (response.ok) {
      let data = undefined;
      try {
        if (model) {
          data = await response.json();
        }
      } catch (error) {
        // meh...
      }

      return {
        data: data && model ? (plainToInstance(model, data) as T) : undefined,
        headers: response.headers,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      };
    } else {
      throw this.createError(response.status, await response.text());
    }
  }

  private transformBody(request: Post<unknown>) {
    if (request.data && typeof request.data === 'object') {
      request.config['body'] = JSON.stringify(instanceToPlain(request.data));
    } else if (request.config.body && typeof request.config.body === 'object') {
      request.config['body'] = JSON.stringify(instanceToPlain(request.config.body));
    }
    return request;
  }

  public async exec<T>(url: string, config: RequestInit) {
    return (await this.execWithResponseMeta<T>({ url, config })).data;
  }

  public async execNoTransform(url: string, request: RequestInit) {
    const response = await this.httpClient.fetch(url, request);
    if (response.ok) {
      return response;
    } else {
      throw this.createError(response.status, await response.text());
    }
  }

  public post<T extends object = undefined>(args: Post<T>) {
    if (!args.config) {
      args.config = {};
    }

    this.transformBody(args);

    args.config['method'] = 'POST';
    return this.execWithResponseMeta<T>(args);
  }

  private resolveFromCache<T>(url: string) {
    const entry = this.cache.get(url);
    if (entry) {
      if (entry.timestamp + this.cacheTimeout > Date.now()) {
        return entry.data as ResponseMeta<T>;
      } else {
        this.cache.delete(url);
      }
    }
    return null;
  }

  private addToCache(url: string, data: ResponseMeta<object>) {
    this.cache.set(url, {
      data,
      timestamp: Date.now(),
    });
  }

  private removeFromCache(url: string) {
    this.cache.delete(url);
  }

  public async get<T extends object>(args: Get<T>, options: GetOptions = { cache: true }) {
    if (!args.config) {
      args.config = {};
    }

    args.config['method'] = 'GET';

    if (args.config?.body) {
      delete args.config.body;
    }

    if (options.cache) {
      const cached = this.resolveFromCache<T>(args.url);
      if (cached) {
        return cached;
      }
    }

    const res = await this.execWithResponseMeta<T>(args);
    if (options.cache) {
      this.addToCache(args.url, res);
    }
    return res;
  }

  public async delete(args: Delete) {
    if (!args.config) {
      args.config = {};
    }

    args.config['method'] = 'DELETE';

    if (args.config?.body) {
      delete args.config.body;
    }

    this.removeFromCache(args.url);
    await this.execNoTransform(args.url, args.config);
  }

  public async downloadBlob(url: string, config?: RequestInit) {
    const response = await this.execNoTransform(url, {
      ...config,
      method: 'GET',
    });

    let fileName = 'unknown';
    const disposition = response.headers.get('content-disposition');

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    const blob = await response.blob();
    return {
      blob,
      size: blob.size,
      fileName,
    };
  }

  public async uploadFile(formData: FormData, url: string, config?: RequestInit) {
    const response = await this.httpClient.fetch(url, {
      ...config,
      method: 'POST',
      body: formData,
    });

    this.execWithResponseMeta;

    if (response.ok) {
      const data = await response.json();
      return plainToInstance(FileAttachment, data);
    } else {
      throw this.createError(response.status, await response.text());
    }
  }

  public fileFormdata(file: File) {
    const formData = new FormData();
    formData.append('files', file, file?.name ?? 'file');
    return formData;
  }
}
